.gen-style-calendar {
  position: relative;

  > input {
    margin: 0px 8px;
    padding: 8px;
    background: rgba(0, 0, 0, 0.25098);
    border: none;
    border-radius: 4px;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    color: #f0f0f0;
  }
}

.gen-style-calendar-overlay-wrapper {
  position: absolute;
  top: calc(100% + 3px);
  left: 4px;
  z-index: 1000;
}

.gen-style-calendar-overlay {
  background-color: #1c232c;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25), 0px 4px 5px rgba(0, 0, 0, 0.22), 0px 1px 10px rgba(0, 0, 0, 0.4);
  border-radius: 4px;

  .DayPicker-Day {
    background-color: transparent;
    color: #f0f0f0;
  }

  .DayPicker-Day:hover {
    background-color: rgba(240, 240, 240, 0.301961) !important;
    border-radius: 4px;
  }

  .DayPicker-Day.DayPicker-Day--today {
    background: #a83a36;
    border-radius: 4px;
  }

  .DayPicker-Day.DayPicker-Day--selected {
    background: #00aeef;
    border-radius: 4px;
    color: #181e26 !important;
  }

  .DayPicker-Weekday {
    color: #bfa593;
  }
}
