$side-pane-width: 400px;

#side-panel-padding {
  width: $side-pane-width;
  flex-shrink: 0;
}

#side-panel {
  position: fixed;
  top: 56px;
  left: 0;
  width: 100%;
  height: calc(100% - 56px);
  z-index: 100;
  pointer-events: none;

  @media (max-width: $screen-xl-min) {
    background-color: transparent;
    transition: ease background-color 250ms;
  }

  &.open {
    @media (max-width: $screen-xl-min) {
      pointer-events: auto;
      background-color: rgba(0, 0, 0, 0.25);
    }
  }

  #side-panel-content {
    background-color: #1f252f;
    height: 100%;
    width: $side-pane-width;
    pointer-events: all;

    @media (max-width: $screen-xl-min) {
      position: absolute;
      top: 0;
      left: -$side-pane-width;
      transition: ease left 500ms;
    }

    &.open {
      @media (max-width: $screen-xl-min) {
        left: 0;
      }
    }

    #side-panel-body {
      flex: 1;
      padding: 16px;

      .separator {
        padding: 1em 0;
      }

      #advanced-filter-container {
        #type-container {
          display: grid;
          grid-template-columns: repeat(5, 1fr);
        }

        #high-perf-line-container {
          display: grid;
          grid-template-columns: 1fr 1fr;
        }

        #form-factor-container {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
        }

        #manufacturer-container {
          display: grid;
          grid-template-columns: 1fr 1fr;
        }
      }
    }

    #side-panel-header {
      padding: 8px;
      box-shadow: 0px 4px 5px 0px #00000033;
      box-shadow: 0px 3px 14px 0px #0000001f;
      box-shadow: 0px 8px 10px 0px #00000024;
    }
  }
}
