#unit-info-container {
  padding: 28px;
  gap: 36px;

  .quick-info {
    gap: 36px;

    .service-tag {
      display: flex;
      align-items: center;
      gap: 8px;
      > gen-text {
        margin-bottom: -16px;
      }
    }

    .manufacturer-info {
      gap: 16px;

      .manufacturer-logo-image {
        gap: 8px;
        .contract-manufacturer-image {
          width: 80px;
          height: 44px;
        }
      }
    }

    gen-separator {
      height: 54px;
    }
  }

  .request-image-no-backup {
    display: grid;
  }

  .request-image-no-backup gen-text {
    white-space: pre-line;
    font-size: 0.6em;
  }

  .second-row {
    display: grid;
    grid-template-columns: repeat(4, max-content) auto max-content;
    column-gap: 1em;

    .download-xml {
      margin-left: auto;
    }
  }
  .third-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(6, max-content);
    grid-auto-flow: column;
    row-gap: 0.5em;
    column-gap: 2em;
    > div {
      display: grid;
      grid-template-columns: auto max-content;
      border-bottom: 1px solid rgba(255, 255, 255, 0.05);
      box-shadow: 0px 0px 0px -1px rgba(0, 0, 0, 0.4);
    }
  }
  .fourth-row {
    display: grid;
    grid-template-columns: repeat(4, max-content) min-content;
    column-gap: 1em;
  }

  .fifth-row {
    .table-title {
      display: grid;
      grid-template-columns: auto max-content;
    }
  }
}
