.bottom-right-popup {
  position: absolute;
  bottom: 10px;
  right: 10px;

  .gen-padding {
    padding: var(--gen-spacing-base);
  }
}

.unit-list-table {
  height: 100%;
}

.svItems {
  margin-right: 1em;
}
